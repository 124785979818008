'use strict';

$(document).ready(function () {
    $('#maincontent').css('margin-top', $('.icon-navigation').height());
    var isPromoBlockVisible = $('.slide-up').length > 0 && !$('.slide-up').hasClass('d-none');
    var promoBlockHeight = $('.slide-up').length > 0 ? $('.slide-up').height() : 0;
    var headerHeight = $('.header-container').height();

    $(window).on('scroll', function() {
        handleHeight(isPromoBlockVisible, promoBlockHeight, headerHeight);
    });

    $('body').on('plp:sizeUpdate', function() {
        handleHeight(isPromoBlockVisible, promoBlockHeight, headerHeight);
    });
});

function handleHeight(isPromoBlockVisible, promoBlockHeight, headerHeight) {
    var windowScrollPosition = $(window).scrollTop();
    var height;

    if (!isPromoBlockVisible) {
        height = 1;
    } else {
        height = promoBlockHeight;
    }
    
    if (isPromoBlockVisible) {
        if(height < windowScrollPosition) {
            $('.icon-navigation').css('top', headerHeight);
        } else {
            $('.icon-navigation').css('top', calculateIconNavigationPadding(windowScrollPosition, promoBlockHeight, headerHeight));
        }
    }
}

/**
 * Calculates the padding of the icon navigation block 
 * based on the scroll position and the height of the promotional block + header block.
 * @param {number} windowScrollPosition - The current scroll position of the window.
 * @param {number} promoBlockHeight - The height of the promotional block.
 * @param {number} promoBlockHeight - The height of the header block.
 */
function calculateIconNavigationPadding(windowScrollPosition, promoBlockHeight, headerHeight) {
    var promoBlockVisibleHeight = (promoBlockHeight - windowScrollPosition) + headerHeight;
        
    $('.icon-navigation').css('top', promoBlockVisibleHeight);
}
